import React from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import ContactForm from "../forms/contactform";
import UnsubscribeForm from "../forms/unsunscribeform";
import FaqModal from "../../Sections/Faqs/FaqModal";
function Allpagemodal({
  flag,
  setFlagClose,
  name,
  bodyTitle,
  children,
  modelData,
}) {
  // const faqData = children.props.children || {};

  

  return (
    <>
      <Modal show={flag} onHide={setFlagClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{bodyTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* {
            bodyTitle !== "Frequently Asked Questions" ?  (<div
              dangerouslySetInnerHTML={{ __html: children.props.children }}
            />) : ""
          } */}

          {
            bodyTitle !== "Frequently Asked Questions" ?  (<div
              dangerouslySetInnerHTML={{ __html: children.props.children }}
            />) : ""
          }
          

          {bodyTitle === "Contact us" ? (
            <ContactForm /> 
          ) : bodyTitle === "Unsubscribe" ? (
            <UnsubscribeForm />
          ) : bodyTitle === "Frequently Asked Questions" ? (<FaqModal modelData={modelData}/>) : ""
          
          }


        </Modal.Body>
        <Modal.Footer>
          <div style={{ paddingTop: "0px" }}>
            <button className="btn_close" onClick={setFlagClose}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Allpagemodal;
