import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import "./Header.css";
import { NavLink, createSearchParams, useNavigate } from "react-router-dom";
import { Squeeze } from "hamburger-react";
import { useContext, useState } from "react";
import { HomeContext } from "../../App";
import Allpagemodal from "../../Components/modal/allpagemodal";
import { useEffect } from "react";
import API from "../../service/Api";
import useQuery from "../../utils/useQuery";
import { useForm } from "react-hook-form";

const Header = () => {
  const { handleSubmit } = useForm();

  const initModelData = { name: "", title: "", children: "" };

  const [flag, setFlag] = useState(false);
  const [model, setModel] = useState(initModelData);
  const [apiModel, setApiModel] = useState([]);
  const [isHover, setIsHover] = useState(false);
  

  

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const data = useContext(HomeContext);

  const headerSectionContent = data.customization.headerSection.content;
  const headerSectionSty = data.customization.headerSection.style;
  console.log(headerSectionSty);
  const logo = data.logo_img;

  const headerBackground = {
    border: "1px solid",
    backgroundColor: headerSectionSty.backgroundColor,
    borderColor: headerSectionSty.borderBottom,
  };

  const navlinkColor = {
    color:headerSectionSty.linkColor
  };

  const ctaStyle = {
    backgroundColor: isHover
      ? headerSectionSty.ctaHoverBg
      : headerSectionSty.ctaBg,
    color: isHover
      ? headerSectionSty.ctaHoverTextColor
      : headerSectionSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover
      ? headerSectionSty.ctaHoverBg
      : headerSectionSty.ctaBg,
    borderLeftColor: isHover
      ? headerSectionSty.ctaHoverBg
      : headerSectionSty.ctaBg,
    borderRightColor: isHover
      ? headerSectionSty.ctaHoverBg
      : headerSectionSty.ctaBg,
    borderBottomColor: isHover
      ? headerSectionSty.ctaHoverBg
      : headerSectionSty.ctaBg,
  };

  useEffect(() => {
    fetch("data/website_config.json")
      .then((res) => res.json())
      .then((data) => {
        // setWebname(data);
        handleApiCall(data.WEBSITE_NAME, data.WEBSITE_ID);
      });
  }, []);

  const handleApiCall = async (web, websiteId) => {
    try {
      const article = { websiteName: web, webSiteId: websiteId };

      let res = await API.post("Content/getWebsiteContent", article);

      setApiModel(res.data[0]);
    } catch (error) {
      console.error("whySectionerror", error.message);
    }
  };

  const setShow = (arg) => {
    const x = JSON.parse(apiModel[arg] ? apiModel[arg] : {});

    setModel({ name: "", title: x.heading, children: x.content.text });
    setFlag(true);
  };

  // redirect btn

  const query = useQuery();
  const navigate = useNavigate();

  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";

  const onSubmit = () => {
    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
    };

    const params = {
      email: myData.email || "",
      firstName: myData.firstName || "",
      lastName: myData.lastName || "",
      s1: myData.utm_campaign || "",
      s2: myData.s2 || "",
      s3: myData.s3 || "",
      uid: myData.uid || "",
      utm_medium: myData.utm_medium || "",
      amount: myData.amount || "",
    };

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        document.querySelector(".nav_bg").classList.add("active_header");
      } else {
        document.querySelector(".nav_bg").classList.remove("active_header");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

const navColor = {
  color: headerSectionSty.linkColor,
};
const styleHeaderHover = {
color: headerSectionSty.linkHoverColor,
};

  return (
    <>
      {["lg"].map((expand, index) => (
        <div key={index} style={{ background: "transparent" }}>
          <Navbar expand={expand} className="nav_bg header">
            <Container fluid>
              <div className="container">
                <div className="nav_parent">
                  <Navbar.Brand href="/" className="logoCenterText2">
                    <h1 className="header_logo">
                      <img
                        src={`static/assets/images/${logo}`}
                        alt={data.domain_name}
                      />
                    </h1>
                  </Navbar.Brand>
                  <div className="d-flex">
                    <Navbar
                      aria-controls={`offcanvasNavbar-expand-${expand}`}
                      className="Hamburger_boder "
                    >
                      <div className="Login_bar_main">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="but_nav_start but_display">
                            <button type="submit" className="login_img_sty">
                              {
                                <img
                                  src={`static/assets/images/login_img.png`}
                                  alt="login-img"
                                />
                              }
                            </button>
                          </div>
                        </form>
                      </div>
                    </Navbar>

                    <Navbar.Toggle className="hamburger_main">
                      <div className="hamburger_sty" style={headerBackground}>
                        <Squeeze direction="right" size={20} color="#fff" />
                      </div>
                    </Navbar.Toggle>
                  </div>

                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    className="button button-effect offcanvas-top"
                  >
                    <Offcanvas.Body className="contact_bg">
                      <Nav className="justify-content-center flex-grow-1 ulstylink">
                        <div style={headerBackground}  className="link-box">
                          <NavLink
                            className="header_link"
                            // style={navlinkColor}
                            style={navlinkColor}
                            onMouseEnter={(e)=>{
                              console.log(e.target)
                              e.target.style='color:'+headerSectionSty.linkHoverColor
                            }}
                            onMouseLeave={(e)=>{
                              console.log(e.target)
                              e.target.style='color:'+headerSectionSty.linkColor
                            }}
                            onClick={() => setShow("how_it_works")}
                          >
                            How it works
                          </NavLink>
                          <NavLink
                            className="header_link"
                            style={navlinkColor}
                            onMouseEnter={(e)=>{
                              console.log(e.target)
                              e.target.style='color:'+headerSectionSty.linkHoverColor
                            }}
                            onMouseLeave={(e)=>{
                              console.log(e.target)
                              e.target.style='color:'+headerSectionSty.linkColor
                            }}
                            onClick={() => setShow("rates")}
                          >
                            Rate & Fees
                          </NavLink>
                          <NavLink
                            className="header_link"
                            style={navlinkColor}
                            onMouseEnter={(e)=>{
                              console.log(e.target)
                              e.target.style='color:'+headerSectionSty.linkHoverColor
                            }}
                            onMouseLeave={(e)=>{
                              console.log(e.target)
                              e.target.style='color:'+headerSectionSty.linkColor
                            }}
                            onClick={() => setShow("contact")}
                          >
                            Contact Us
                          </NavLink>
                        </div>
                      </Nav>
                      <div className="but_nav_start but_display">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Button
                            className="get_start_btn"
                            type="submit"
                            style={ctaStyle}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            {headerSectionContent.ctaText}
                          </Button>
                        </form>
                      </div>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </div>
              </div>
            </Container>
          </Navbar>
        </div>
      ))}

      <Allpagemodal
        flag={flag}
        setFlagClose={() => setFlag(false)}
        name={model["name"]}
        bodyTitle={model["title"]}
      >
        <>{model["children"]}</>
      </Allpagemodal>
    </>
  );
};

export default Header;
