import React, { useContext, useState } from "react";
import { HomeContext } from "../../App";
import { Accordion } from "react-bootstrap";

const FaqModal = (props) => {
  const FaqModalData = JSON.parse(props.modelData.children);
  const data = useContext(HomeContext);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const faqSectionContent = data.customization.faq.content;
  const faqSectionSty = data.customization.faq.style;

  const activeQus = {
    color: faqSectionSty.themeColor2,
  };
  const borderSty = {
    border: "1px solid",
    borderColor: isHover
      ? faqSectionSty.themeColor2
      : faqSectionSty.paraTextColor,
  };

  const handleMouseEnter = (index) => {
    setIsHover(index + 1);
  };

  const handleMouseLeave = (index) => {
    if (index !== "notActive") {
      setIsHover(0);
    }
  };

  const themeColor = {
    color: faqSectionSty.themeColor2 ? faqSectionSty.themeColor2 : "",
  };

  const faqBackground = {
    backgroundColor: faqSectionSty.backgroundColor
      ? faqSectionSty.backgroundColor
      : "",
  };

  const sectionTitle = {
    color: faqSectionSty.titleColor ? faqSectionSty.titleColor : "",
  };

  const quesText = {
    backgroundColor: faqSectionSty.quesbarbackground
      ? faqSectionSty.quesbarbackground
      : "",
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const activeTextQus = {
    color: faqSectionSty.quesTextColor ? faqSectionSty.quesTextColor : "",
  };
  const answerbarStyle = {
    backgroundColor: faqSectionSty.answerbarbackground
      ? faqSectionSty.answerbarbackground
      : "",
    color: faqSectionSty.paraTextColor ? faqSectionSty.paraTextColor : "",
  };

  return (
    <div>
      {/* FAQ */}
      <div>
        {/* <!-- 7thBlock --> */}
        <div className="container-fluid m-0 p-0 bolck_bg website_blocks FooterFaqs_main">
          <div className="container">
            <div className="">
              <div className="FaqBlockContainer paddingfaqblock">
                <div className="">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample2"
                  >
                    <Accordion>
                      {FaqModalData.faq.map((item, index) => (
                        <Accordion.Item
                          key={item.id}
                          eventKey={item.id}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={
                            isActive === index + 1
                              ? () => handleMouseLeave("notActive")
                              : () => handleMouseLeave(index)
                          }
                          style={
                            isHover === index + 1 || isActive === index + 1
                              ? {}
                              : {}
                          }
                        >
                          <Accordion.Header
                            className="qus_txt"
                            style={activeTextQus}
                          >
                            <p
                              className="faq_qus"
                              style={
                                isActive === index + 1
                                  ? activeQus
                                  : {} && activeTextQus
                              }
                            >
                              {" "}
                              {item.qus}
                            </p>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p
                              style={answerbarStyle}
                              className="ans_txt"
                              dangerouslySetInnerHTML={{ __html: item.ans }}
                            ></p>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqModal;
